// 恐懼貪婪＋幣價
'use client';

import { useState, useEffect } from 'react';
import { INDEX_API_ROUTER_PATH } from '@/common/constant';
import Greedy from '@/components/index/Greedy'; // 恐懼貪婪指數
import CoinPrice from '@/components/index/CoinPrice'; // 幣價顯示
import GreedyPop from './GreedyPop'; // 指數說明彈窗
import axios from 'axios';
import moment from 'moment';

const GreedyAndPrice = () => {
    const [greed, setGreed] = useState(null); // 恐貪指數

    // 取得恐貪指數
    const getGreedyIndex = async () => {
        try {
            const { data } = await axios(
                INDEX_API_ROUTER_PATH.getGreedIndex + moment().format('YYYY-MM-DD')
            );

            if (data) setGreed(Math.round(data?.['fear_and_greed']?.score));
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        getGreedyIndex();
    }, []);

    return (
        <div className="relative z-[6] max-w-[1196px] ulg:hidden h-[47px] flex items-center">
            <div className="relative z-[1] uxl:noScrollX">
                {/* <div className="absolute top-0 right-0 bg-opacity-[.5] bg-[#F7F7F8] dark:bg-[rgba(57,57,57,.2)] h-full w-screen" /> */}
                {/* 恐懼貪婪指數 */}
                <div className="between-center gap-x-[35px] py-[7px] px-[45px] uxl:px-[25px] w-full uxl:w-max">
                    {greed && <Greedy greed={greed} />}

                    {/* 幣價顯示 */}
                    <CoinPrice />
                </div>
            </div>

            {/* 指數說明彈窗 */}
            {greed && <GreedyPop greed={greed} />}
        </div>
    );
};

export default GreedyAndPrice;
